import { OperatingSystem, useOS } from '@web/atomic/obj.custom-hooks/mobile.hook';
import { hasWindow } from '@web/utils/platform';
import React, { useEffect } from 'react';
import { PageProps } from '../utils/local-types';

const MesengerApp: React.FunctionComponent<PageProps> = () => {
  const os = useOS();
  let href = null;
  switch (os) {
    case OperatingSystem.Android:
      href = 'https://play.google.com/store/apps/details?id=com.facebook.orca';
      break;
    case OperatingSystem.IOS:
      href = 'https://apps.apple.com/br/app/messenger/id454638411';
      break;
    case OperatingSystem.Other:
      href = 'https://www.facebook.com/';
      break;
    default:
      break;
  }
  useEffect(() => {
    // https://stackoverflow.com/a/31223302/3670829
    setTimeout(() => {
      if (href && hasWindow()) {
        window.location.href = href;
      }
    }, 250);
  }, [os, href]);

  return (
    <p>
      redirecionando para download do <a href={href}>Messenger</a>...
    </p>
  );
};

export default MesengerApp;
